import {toArray} from '@ivosabev/helpers/toArray';
import {useForm, type FormErrors, type UseFormInput, type UseFormReturnType} from '@mantine/form';
import {type FetcherWithComponents, useActionData, useFetcher, useLocation, useNavigation} from '@remix-run/react';
import {useCallback, useEffect} from 'react';

type _TransformValues<Values> = (values: Values) => unknown;

type StandardError = {
  code: string;
  message: string;
  path: string[];
};

type FetcherData = {
  data?: Record<string, any>;
  errors?: StandardError | StandardError[];
  ok: boolean;
};

export type UseFormAdvancedReturnType<
  Values = Record<string, unknown>,
  TransformValues extends _TransformValues<Values> = (values: Values) => Values,
> = UseFormReturnType<Values, TransformValues> & {
  isLoading: boolean;
  handleSubmit: (event?: React.FormEvent<HTMLFormElement> | undefined) => void;
  action: string;
  fetcher: FetcherWithComponents<FetcherData>;
};

export function useFormAdvanced<
  Values = Record<string, unknown>,
  TransformValues extends _TransformValues<Values> =(values: Values) => Values,
>(props?: UseFormInput<Values, TransformValues>): UseFormAdvancedReturnType<Values, TransformValues> {
  const form = useForm({
    clearInputErrorOnChange: true,
    validateInputOnBlur: true,
    validateInputOnChange: true,
    ...props,
  });

  const location = useLocation();
  const action = `${location.pathname}${location.search}${location.hash}`;
  const fetcher = useFetcher<FetcherData>({key: action});

  const navigation = useNavigation();
  const actionData = useActionData<FetcherData>();
  const fetcherData = fetcher.data;
  const data = fetcherData ?? actionData;
  const state = fetcher.state ?? navigation.state;

  useEffect(() => {
    // TODO: Extract as utility that converts StandardError to FormErrors
    const errors = toArray(data?.errors ?? []).reduce((p: FormErrors, c: StandardError) => {
      const path = c.path.length ? c.path.join('.') : '$form';
      p[path] = p[path] ? `${p[path]}\n${c.message}` : c.message;
      return p;
    }, {} as FormErrors);
    form.setErrors(errors);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = form.onSubmit((values: TransformValues) => {
    return fetcher.submit(values as Record<string, any>, {action, method: 'post'});
  });

  const isLoading = state === 'submitting' || state === 'loading';

  const register = useCallback((name: string) => {
    return {name, ...form.getInputProps(name)};
  }, [form]);

  return {action, fetcher, handleSubmit, isLoading, register, ...form};
}
